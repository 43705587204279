<template>
  <div class="tributacao-app-container">
    <!-- Notificação de Sucesso -->
    <div v-if="showSuccessMessage" class="tributacao-success-notification">
      {{ successMessage }}
    </div>

    <!-- Abas de Navegação -->
    <div class="tributacao-tabs">
      <button
        :class="['tributacao-tab', { active: activeTab === 'gerenciamento' }]"
        @click="activeTab = 'gerenciamento'"
      >
        Gerenciamento de Classes
      </button>
      <button
        :class="['tributacao-tab', { active: activeTab === 'configuracao' }]"
        @click="activeTab = 'configuracao'"
      >
        Configuração de Classes
      </button>
      <button
        :class="['tributacao-tab', { active: activeTab === 'classes-transportador' }]"
        @click="activeTab = 'classes-transportador'"
      >
        Classes x Transportador
      </button>
    </div>

    <div>
      <!-- Gerenciamento de Classes -->
      <div v-if="activeTab === 'gerenciamento'" class="tributacao-gerenciamento-container">
        <!-- Cabeçalho com Botão de Adição e Barra de Pesquisa -->
        <div class="tributacao-header-gerenciamento">
          <button @click="openAddClassModal" class="tributacao-add-button">Adicionar Nova Classe</button>
          <div class="tributacao-search-bar">
            <input 
              type="text" 
              v-model="searchQuery" 
              placeholder="Pesquisar classes..." 
              class="tributacao-search-input"
            />
            <svg 
              class="tributacao-search-icon" 
              xmlns="http://www.w3.org/2000/svg" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
        </div>

        <!-- Tabela de Classes -->
        <div class="tributacao-classes-list">
          <table class="tributacao-classes-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Descrição</th>
                <th>Ativo</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="classe in paginatedClasses" 
                :key="classe.id"
                :class="{ 'tributacao-inactive-class': !classe.ativo }"
              >
                <td>{{ classe.id }}</td>
                <td>{{ classe.descricao }}</td>
                <td>
                  <input
                    type="checkbox"
                    v-model="classe.ativo"
                    @change="toggleAtivo(classe)"
                    class="tributacao-form-input-checkbox"
                  />
                </td>
                <td>
                  <button @click="openEditClassModal(classe)" class="tributacao-action-button tributacao-edit-button">Editar</button>
                </td>
              </tr> 
            </tbody>
          </table>
        </div>

        <!-- Paginação -->
        <div class="tributacao-pagination">
          <button 
            :disabled="currentPage === 1" 
            @click="changePage(currentPage - 1)" 
            class="tributacao-pagination-button"
          >
            Anterior
          </button>
          <span class="tributacao-pagination-info">Página {{ currentPage }} de {{ totalPages }}</span>
          <button 
            :disabled="currentPage === totalPages" 
            @click="changePage(currentPage + 1)" 
            class="tributacao-pagination-button"
          >
            Próxima
          </button>
        </div>
      </div>

      <!-- Configuração de Classes -->
      <div v-if="activeTab === 'configuracao'" class="tributacao-configuracao-container">
        <!-- Seleção de Classe, UF do Transportador e Regime -->
        <div class="tributacao-selection-section">
          <div class="tributacao-form-group">
            <label for="select-classe" class="tributacao-form-label">Selecione a Classe:</label>
            <div class="tributacao-custom-select-container">
              <select id="select-classe" v-model="selectedClass" class="tributacao-custom-select">
                <option value="" disabled>Selecione uma classe</option>
                <option v-for="classe in classes" :key="classe.id" :value="classe.id">
                  {{ classe.descricao }}
                </option>
              </select>
              <span class="tributacao-select-arrow">&#9662;</span>
            </div>
            <span v-if="errors.classe" class="tributacao-error-message">{{ errors.classe }}</span>
          </div>

          <div class="tributacao-form-group">
            <label for="select-uf" class="tributacao-form-label">Selecione a UF do Transportador:</label>
            <div class="tributacao-custom-select-container">
              <select id="select-uf" v-model="selectedUF" class="tributacao-custom-select">
                <option value="" disabled>Selecione uma UF</option>
                <option v-for="uf in estados" :key="uf" :value="uf">{{ uf }}</option>
              </select>
              <span class="tributacao-select-arrow">&#9662;</span>
            </div>
            <span v-if="errors.ufTransportador" class="tributacao-error-message">{{ errors.ufTransportador }}</span>
          </div>

          <div class="tributacao-form-group">
            <label for="select-regime" class="tributacao-form-label">Selecione o Regime:</label>
            <div class="tributacao-custom-select-container">
              <select id="select-regime" v-model="selectedRegime" class="tributacao-custom-select">
                <option value="" disabled>Selecione um regime</option>
                <option v-for="regime in regimes" :key="regime.id" :value="regime.id">{{ regime.descricao }}</option>
              </select>
              <span class="tributacao-select-arrow">&#9662;</span>
            </div>
            <span v-if="errors.regime" class="tributacao-error-message">{{ errors.regime }}</span>
          </div>
        </div>

        <!-- Seleção de Tributação para Exibição -->
        <div class="tributacao-info-filter">
          <label for="info-filter" class="tributacao-form-label">Selecione a Tributação para Exibir:</label>
          <div class="tributacao-custom-select-container">
            <select id="info-filter" v-model="selectedField" class="tributacao-custom-select">
              <option value="" disabled>Selecione uma opção</option>
              <option v-for="field in availableFields" :key="field.value" :value="field.value">
                {{ field.label }}
              </option>
            </select>
            <span v-if="errors.selectedField" class="tributacao-error-message">{{ errors.selectedField }}</span>
            <span class="tributacao-select-arrow">&#9662;</span>
          </div>
        </div>

        <!-- Mensagem de Alerta quando Regime ou UF de Transporte não estão selecionados -->
        <div v-if="showAlert" class="tributacao-alert-warning">
          <p>Por favor, selecione uma <strong>Classe</strong>, uma <strong>UF de Transporte</strong> e um <strong>Regime</strong> antes de configurar a tributação.</p>
        </div>

        <!-- Tabela de Tributação -->
        <div v-if="showTributacaoTable" class="tributacao-table-container">
          <div class="tributacao-section">
            <h3>Configuração de Tributação para Classe: {{ selectedClasseDescricao }}</h3>
            
            <!-- Indicador de Carregamento ou Tabela de Tributação -->
            <div v-if="loadingTributacoesClasse" class="tributacao-loader">
              Carregando tributações da classe...
            </div>
            <div v-else-if="tributacoesClasseLoaded" class="tributacao-table-container">
              <table class="tributacao-table">
                <thead>
                  <tr>
                    <th class="tributacao-flag-cell">
                      <img src="@/assets/brasil.png" alt="Bandeira do Brasil" class="tributacao-flag-image" />
                    </th>
                    <th
                      v-for="(estado, colIndex) in estados"
                      :key="estado"
                      :class="{ 'tributacao-highlight-border': colIndex === tributacaoClassHoverColIndex }"
                    >
                      {{ estado }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(estadoOrigem, rowIndex) in estados" :key="estadoOrigem">
                    <td :class="['tributacao-state-column', { 'tributacao-highlight-border': rowIndex === tributacaoClassHoverRowIndex }]">
                      {{ estadoOrigem }}
                    </td>
                    <td
                      v-for="(estadoDestino, colIndex) in estados"
                      :key="estadoDestino"
                      @mouseover="setTributacaoClassHoverIndices(rowIndex, colIndex)"
                      @mouseleave="resetTributacaoClassHoverIndices"
                      :class="[
                        'tributacao-clickable-cell',
                        { 'tributacao-same-origin-dest': estadoOrigem === estadoDestino }
                      ]"
                      @dblclick="openTributacaoConfigModal(estadoOrigem, estadoDestino)"
                    >
                      <!-- Exibição Condicional do Campo Selecionado -->
                      <div class="tributacao-cell-display">
                        <span v-if="tributacoesClasse.completeData[estadoOrigem][estadoDestino]?.[currentSelectedTributacao]">
                          <span v-if="currentSelectedTributacao === 'aliquota' || currentSelectedTributacao === 'reducao'">
                            {{ formatCurrency(tributacoesClasse.completeData[estadoOrigem][estadoDestino][currentSelectedTributacao] || 0) }}
                          </span>
                          <span v-else>
                            {{ tributacoesClasse.completeData[estadoOrigem][estadoDestino][currentSelectedTributacao] }}
                          </span>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="tributacao-no-data">
              Nenhuma tributação configurada para esta classe.
            </div>
          </div>
        </div>
      </div>

      <!-- Classes x Transportador -->
      <div v-if="activeTab === 'classes-transportador'" class="tributacao-classes-transportador-container">
        <h2>Classes x Transportador</h2>
        
        <!-- Seleção de Transportador -->
        <div class="tributacao-form-group">
          <label for="select-transportador" class="tributacao-form-label">Selecione um Transportador:</label>
          <div class="tributacao-custom-select-container">
            <select 
              id="select-transportador" 
              v-model="selectedTransportador" 
              @change="buscarClasseDoTransportador" 
              class="tributacao-custom-select"
              :disabled="isFetchingTransportadores"
            >
              <option value="" disabled>Selecione um transportador</option>
              <option v-for="transportador in transportadores" :key="transportador.id" :value="transportador.id">
                {{ transportador.nomeFantasia }} ({{ transportador.razaoSocial }})
              </option>
            </select>
            <span class="tributacao-select-arrow">&#9662;</span>
            <span v-if="isFetchingTransportadores" class="tributacao-loading-text">Carregando...</span>
          </div>
          <span v-if="errorsTransportador.transportador" class="tributacao-error-message">{{ errorsTransportador.transportador }}</span>
        </div>

        <!-- Informação sobre Classe do Transportador -->
        <div v-if="transportadorInfo" class="tributacao-transportador-info">
          <p>
            Transportador <strong>{{ getTransportadorNome(selectedTransportador) }}</strong> 
            {{ transportadorInfo.idClasse === 0 ? "não possui nenhuma classe atribuída." : "já possui a classe: " + getClasseDescricao(transportadorInfo.idClasse) + "." }}
          </p>

          <!-- Botão para Atribuir Classe quando não há classe -->
          <button 
            v-if="transportadorInfo.idClasse === 0" 
            @click="abrirAtribuirClasseModal(false)" 
            class="tributacao-btn tributacao-btn-primary"
          >
            Atribuir Classe
          </button>

          <!-- Botões para Editar e Remover Classe quando já há uma classe atribuída -->
          <div v-else>
            <button 
              @click="abrirAtribuirClasseModal(true)" 
              class="tributacao-btn tributacao-btn-primary"
            >
              Editar Classe
            </button>

          </div>
        </div>

        <!-- Modal para Atribuir ou Editar Classe -->
        <div v-if="showAtribuirClasseModal" class="tributacao-modal">
          <div class="tributacao-modal-content">
            <button class="tributacao-close-button" @click="fecharAtribuirClasseModal">&times;</button>
            <h2 class="tributacao-modal-title">
              {{ isEditingTransportadorClass ? 'Editar Classe do Transportador' : 'Atribuir Classe ao Transportador' }}
            </h2>
            <form @submit.prevent="atribuirClasseAoTransportador" class="tributacao-modal-form">
              <div class="tributacao-form-group">
                <label for="select-classe-atribuir" class="tributacao-form-label">Selecione a Classe:</label>
                <div class="tributacao-custom-select-container">
                  <select 
                    id="select-classe-atribuir" 
                    v-model="selectedClasseParaAtribuir" 
                    class="tributacao-custom-select"
                  >
                    <option value="" disabled>Selecione uma classe</option>
                    <option v-for="classe in classesDisponiveis" :key="classe.id" :value="classe.id">
                      {{ classe.descricao }} <span v-if="!classe.ativo">(Inativa)</span>
                    </option>
                  </select>
                  <span class="tributacao-select-arrow">&#9662;</span>
                </div>
                <span v-if="errorsTransportador.classe" class="tributacao-error-message">{{ errorsTransportador.classe }}</span>
              </div>
              <div class="tributacao-form-actions">
                <button type="submit" class="tributacao-btn tributacao-btn-primary">
                  {{ isEditingTransportadorClass ? 'Atualizar' : 'Atribuir' }}
                </button>
                <button type="button" @click="fecharAtribuirClasseModal" class="tributacao-btn tributacao-btn-secondary">Cancelar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Modais Adicionais -->
    <!-- Modal de Tributação para Edição de Classe -->
    <div v-if="showModalTributacaoClass" class="tributacao-modal-class">
      <div class="tributacao-modal-content-class">
        <button class="tributacao-close-button" @click="closeTributacaoClassModal">&times;</button>
        <h2 class="tributacao-modal-title">Editar Tributação da Classe</h2>
        <form @submit.prevent="saveTributacaoClassData" class="tributacao-modal-form">
          <!-- UF Transportador (Read-only) -->
          <div class="tributacao-form-group">
            <label class="tributacao-form-label">UF Transportador:</label>
            <input type="text" :value="currentTributacao.ufTransportador" readonly class="tributacao-form-input" />
          </div>
          <!-- Origem (Read-only) -->
          <div class="tributacao-form-group">
            <label class="tributacao-form-label">Origem:</label>
            <input type="text" :value="currentTributacao.ufRemetente" readonly class="tributacao-form-input" />
          </div>
          <!-- Destino (Read-only) -->
          <div class="tributacao-form-group">
            <label class="tributacao-form-label">Destino:</label>
            <input type="text" :value="currentTributacao.ufDestinatario" readonly class="tributacao-form-input" />
          </div>
          <!-- CFOP -->
          <div class="tributacao-form-group">
            <label for="cfop-class" class="tributacao-form-label">CFOP:</label>
            <input 
              type="text" 
              id="cfop-class" 
              v-model="tributacaoClassData.cfop" 
              class="tributacao-form-input"
              :class="{ 'tributacao-input-error': errors.tributacaoClassData.cfop }"
              required 
            />
            <span v-if="errors.tributacaoClassData.cfop" class="tributacao-error-message">{{ errors.tributacaoClassData.cfop }}</span>
          </div>
          <!-- CST -->
          <div class="tributacao-form-group">
            <label for="cst-class" class="tributacao-form-label">CST:</label>
            <input 
              type="text" 
              id="cst-class" 
              v-model="tributacaoClassData.cst" 
              class="tributacao-form-input"
              :class="{ 'tributacao-input-error': errors.tributacaoClassData.cst }"
              required 
            />
            <span v-if="errors.tributacaoClassData.cst" class="tributacao-error-message">{{ errors.tributacaoClassData.cst }}</span>
          </div>
          <!-- Indicador Simples Nacional (Condicional) -->
          <div class="tributacao-form-group" v-if="isSimplesNacionalClass">
            <label for="indSn-class" class="tributacao-form-label">Indicador Simples Nacional:</label>
            <div class="tributacao-custom-select-container">
              <select 
                id="indSn-class" 
                v-model="tributacaoClassData.indSn" 
                class="tributacao-custom-select"
                :class="{ 'tributacao-input-error': errors.tributacaoClassData.indSn }"
                required
              >
                <option value="" disabled>Selecione uma opção</option>
                <option :value="1">Contribuição substituída de modo integral</option>
                <option :value="2">Contribuição sem substituição</option>
                <option :value="3">Contribuição não substituída concomitante com contribuição substituída</option>
              </select>
              <span class="tributacao-select-arrow">&#9662;</span>
            </div>
            <span v-if="errors.tributacaoClassData.indSn" class="tributacao-error-message">{{ errors.tributacaoClassData.indSn }}</span>
          </div>
          <div class="tributacao-form-group" v-else>
            <input type="hidden" v-model="tributacaoClassData.indSn" />
          </div>
          <!-- Mensagem Fiscal -->
          <div class="tributacao-form-group">
            <label for="msgFiscal-class" class="tributacao-form-label">Mensagem Fiscal:</label>
            <textarea 
              id="msgFiscal-class" 
              v-model="tributacaoClassData.msgFiscal" 
              class="tributacao-form-input" 
              placeholder="Digite a mensagem fiscal"
              :class="{ 'tributacao-input-error': errors.tributacaoClassData.msgFiscal }"
              required
            ></textarea>
            <span v-if="errors.tributacaoClassData.msgFiscal" class="tributacao-error-message">{{ errors.tributacaoClassData.msgFiscal }}</span>
          </div>
          <!-- Alíquota -->
          <div class="tributacao-form-group">
            <label for="aliquota-class" class="tributacao-form-label">Alíquota:</label>
            <input 
              type="number" 
              id="aliquota-class" 
              v-model.number="tributacaoClassData.aliquota" 
              step="0.01" 
              class="tributacao-form-input"
              :class="{ 'tributacao-input-error': errors.tributacaoClassData.aliquota }"
              required 
            />
            <span v-if="errors.tributacaoClassData.aliquota" class="tributacao-error-message">{{ errors.tributacaoClassData.aliquota }}</span>
          </div>
          <!-- Redução -->
          <div class="tributacao-form-group">
            <label for="reducao-class" class="tributacao-form-label">Redução:</label>
            <input 
              type="number" 
              id="reducao-class" 
              v-model.number="tributacaoClassData.reducao" 
              step="0.01" 
              class="tributacao-form-input"
              :class="{ 'tributacao-input-error': errors.tributacaoClassData.reducao }"
              required 
            />
            <span v-if="errors.tributacaoClassData.reducao" class="tributacao-error-message">{{ errors.tributacaoClassData.reducao }}</span>
          </div>
          <!-- Ações do Formulário -->
          <div class="tributacao-form-actions">
            <button type="submit" class="tributacao-btn tributacao-btn-primary">Salvar</button>
            <button type="button" @click="closeTributacaoClassModal" class="tributacao-btn tributacao-btn-secondary">Cancelar</button>
          </div>
        </form>
      </div>
    </div>

    <!-- Modal de Configuração de Tributação via Duplo Clique -->
    <div v-if="showTributacaoConfigModal" class="tributacao-modal-class">
      <div class="tributacao-modal-content-class">
        <button class="tributacao-close-button" @click="closeTributacaoConfigModal">&times;</button>
        <h2 class="tributacao-modal-title">Configurar Tributação</h2>
        <form @submit.prevent="saveTributacaoConfigData" class="tributacao-modal-form">
          <!-- UF Transportador (Read-only) -->
          <div class="tributacao-form-group">
            <label class="tributacao-form-label">UF Transportador:</label>
            <input type="text" :value="currentTributacao.ufTransportador" readonly class="tributacao-form-input" />
          </div>
          <!-- Origem (Read-only) -->
          <div class="tributacao-form-group">
            <label class="tributacao-form-label">Origem:</label>
            <input type="text" :value="currentTributacao.ufRemetente" readonly class="tributacao-form-input" />
          </div>
          <!-- Destino (Read-only) -->
          <div class="tributacao-form-group">
            <label class="tributacao-form-label">Destino:</label>
            <input type="text" :value="currentTributacao.ufDestinatario" readonly class="tributacao-form-input" />
          </div>
          <!-- CFOP -->
          <div class="tributacao-form-group">
            <label for="cfop-config" class="tributacao-form-label">CFOP:</label>
            <input 
              type="text" 
              id="cfop-config" 
              v-model="currentTributacao.cfop" 
              class="tributacao-form-input"
              :class="{ 'tributacao-input-error': errors.currentTributacao.cfop }"
              required 
            />
            <span v-if="errors.currentTributacao.cfop" class="tributacao-error-message">{{ errors.currentTributacao.cfop }}</span>
          </div>
          <!-- CST -->
          <div class="tributacao-form-group">
            <label for="cst-config" class="tributacao-form-label">CST:</label>
            <input 
              type="text" 
              id="cst-config" 
              v-model="currentTributacao.cst" 
              class="tributacao-form-input"
              :class="{ 'tributacao-input-error': errors.currentTributacao.cst }"
              required 
            />
            <span v-if="errors.currentTributacao.cst" class="tributacao-error-message">{{ errors.currentTributacao.cst }}</span>
          </div>
          <!-- Indicador Simples Nacional (Condicional) -->
          <div class="tributacao-form-group" v-if="isSimplesNacionalClass">
            <label for="indSn-config" class="tributacao-form-label">Indicador Simples Nacional:</label>
            <div class="tributacao-custom-select-container">
              <select 
                id="indSn-config" 
                v-model="currentTributacao.indSn" 
                class="tributacao-custom-select"
                :class="{ 'tributacao-input-error': errors.currentTributacao.indSn }"
                required
              >
                <option value="" disabled>Selecione uma opção</option>
                <option :value="1">Contribuição substituída de modo integral</option>
                <option :value="2">Contribuição sem substituição</option>
                <option :value="3">Contribuição não substituída concomitante com contribuição substituída</option>
              </select>
              <span class="tributacao-select-arrow">&#9662;</span>
            </div>
            <span v-if="errors.currentTributacao.indSn" class="tributacao-error-message">{{ errors.currentTributacao.indSn }}</span>
          </div>
          <div class="tributacao-form-group" v-else>
            <input type="hidden" v-model="currentTributacao.indSn" />
          </div>
          <!-- Mensagem Fiscal -->
          <div class="tributacao-form-group">
            <label for="msgFiscal-config" class="tributacao-form-label">Mensagem Fiscal:</label>
            <textarea 
              id="msgFiscal-config" 
              v-model="currentTributacao.msgFiscal" 
              class="tributacao-form-input" 
              placeholder="Digite a mensagem fiscal"
              :class="{ 'tributacao-input-error': errors.currentTributacao.msgFiscal }"
              required
            ></textarea>
            <span v-if="errors.currentTributacao.msgFiscal" class="tributacao-error-message">{{ errors.currentTributacao.msgFiscal }}</span>
          </div>
          <!-- Alíquota -->
          <div class="tributacao-form-group">
            <label for="aliquota-config" class="tributacao-form-label">Alíquota:</label>
            <input 
              type="number" 
              id="aliquota-config" 
              v-model.number="currentTributacao.aliquota" 
              step="0.01" 
              class="tributacao-form-input"
              :class="{ 'tributacao-input-error': errors.currentTributacao.aliquota }"
              required 
            />
            <span v-if="errors.currentTributacao.aliquota" class="tributacao-error-message">{{ errors.currentTributacao.aliquota }}</span>
          </div>
          <!-- Redução -->
          <div class="tributacao-form-group">
            <label for="reducao-config" class="tributacao-form-label">Redução:</label>
            <input 
              type="number" 
              id="reducao-config" 
              v-model.number="currentTributacao.reducao" 
              step="0.01" 
              class="tributacao-form-input"
              :class="{ 'tributacao-input-error': errors.currentTributacao.reducao }"
              required 
            />
            <span v-if="errors.currentTributacao.reducao" class="tributacao-error-message">{{ errors.currentTributacao.reducao }}</span>
          </div>
          <!-- Ações do Formulário -->
          <div class="tributacao-form-actions">
            <button type="submit" class="tributacao-btn tributacao-btn-primary">Salvar</button>
            <button type="button" @click="closeTributacaoConfigModal" class="tributacao-btn tributacao-btn-secondary">Cancelar</button>
          </div>
        </form>
      </div>
    </div>

    <!-- Modal de Adicionar Classe -->
    <div v-if="showAddClassModal" class="tributacao-modal">
      <div class="tributacao-modal-content">
        <button class="tributacao-close-button" @click="closeAddClassModal">&times;</button>
        <h2 class="tributacao-modal-title">Adicionar Nova Classe</h2>
        <form @submit.prevent="addClass" class="tributacao-modal-form">
          <div class="tributacao-form-group">
            <label for="descricao" class="tributacao-form-label">Descrição:</label>
            <input
              type="text"
              id="descricao"
              v-model="newClassDescription"
              placeholder="Digite a descrição da classe"
              class="tributacao-form-input"
              :class="{ 'tributacao-input-error': errors.addClassData.descricao }"
              required
            />
            <span v-if="errors.addClassData.descricao" class="tributacao-error-message">{{ errors.addClassData.descricao }}</span>
          </div>
          <div class="tributacao-form-actions">
            <button type="submit" class="tributacao-btn tributacao-btn-primary">Salvar</button>
            <button type="button" @click="closeAddClassModal" class="tributacao-btn tributacao-btn-secondary">Cancelar</button>
          </div>
        </form>
      </div>
    </div>

    <!-- Modal de Edição de Classe -->
    <div v-if="showEditClassModal" class="tributacao-modal">
      <div class="tributacao-modal-content">
        <button class="tributacao-close-button" @click="closeEditClassModal">&times;</button>
        <h2 class="tributacao-modal-title">Editar Classe</h2>
        <form @submit.prevent="updateClass" class="tributacao-modal-form">
          <!-- Descrição da Classe -->
          <div class="tributacao-form-group">
            <label for="edit-descricao" class="tributacao-form-label">Descrição:</label>
            <input
              type="text"
              id="edit-descricao"
              v-model="editClassData.descricao"
              placeholder="Digite a descrição da classe"
              class="tributacao-form-input"
              :class="{ 'tributacao-input-error': errors.editClassData.descricao }"
              required
            />
            <span v-if="errors.editClassData.descricao" class="tributacao-error-message">{{ errors.editClassData.descricao }}</span>
          </div>

          <!-- Status Ativo/Inativo -->
          <div class="tributacao-form-group">
            <label class="tributacao-form-label">Ativo:</label>
            <input
              type="checkbox"
              v-model="editClassData.ativo"
              class="tributacao-form-input-checkbox"
            />
          </div>

          <!-- Ações do Formulário -->
          <div class="tributacao-form-actions">
            <button type="submit" class="tributacao-btn tributacao-btn-primary">Salvar</button>
            <button type="button" @click="closeEditClassModal" class="tributacao-btn tributacao-btn-secondary">Cancelar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Tributacao",
  data() {
    return {
      activeTab: "gerenciamento",
      availableFields: [
        { value: 'cfop', label: 'CFOP' },
        { value: 'cst', label: 'CST' },
        { value: 'aliquota', label: 'Alíquota' },
        { value: 'reducao', label: 'Redução' },
        { value: 'msgFiscal', label: 'Mensagem Fiscal' },
      ],
      selectedField: 'aliquota', 
      estados: [
        "AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES",
        "GO", "MA", "MG", "MS", "MT", "PA", "PB", "PE",
        "PI", "PR", "RJ", "RN", "RO", "RR", "RS", "SC",
        "SE", "SP", "TO",
      ],
      regimes: [
        { id: 1, descricao: "SIMPLES NACIONAL" },
        { id: 2, descricao: "LUCRO PRESUMIDO" },
        { id: 3, descricao: "LUCRO REAL" },
      ],
      // Tributacoes para Classe
      tributacoesClasse: {
        cfop: {},
        cst: {},
        msgFiscal: {},
        aliquota: {},
        reducao: {},
        indSn: {},
        completeData: {},
      },
      tributacoesClasseLoaded: false,
      loadingTributacoesClasse: false,

      showModalTributacaoClass: false,
      tributacaoClassData: {
        ufRemetente: "",
        ufDestinatario: "",
        cfop: "",
        cst: "",
        indSn: "",
        msgFiscal: "",
        aliquota: 0,
        reducao: 0,
      },
      classes: [],
      showAddClassModal: false,
      showEditClassModal: false,
      newClassDescription: "",
      editClassData: {
        id: null,
        descricao: "",
        ativo: true,
      },
      showTributacaoConfigModal: false,
      currentTributacao: {
        ufTransportador: "",
        ufRemetente: "",
        ufDestinatario: "",
        idClasse: null,
        regime: null,
        cfop: "",
        cst: "",
        indSn: "",
        msgFiscal: "",
        aliquota: 0,
        reducao: 0,
      },
      selectedClass: null,
      selectedUF: "",
      selectedRegime: "",
      showSuccessMessage: false,
      successMessage: "",
      searchQuery: "",
      currentPage: 1,
      classesPerPage: 7,
      isFetchingClasses: false,
      isFetchingTransportadores: false, 
      isEditingTransportadorClass: false, 

      errors: {
        classe: "",
        ufTransportador: "",
        regime: "",
        selectedField: "",
        tributacaoClassData: {},
        editClassData: {},
        addClassData: {},
        currentTributacao: {},
      },
      // Controle de hover na tabela de tributação
      tributacaoClassHoverRowIndex: null,
      tributacaoClassHoverColIndex: null,

      // Dados para a aba Classes x Transportador
      transportadores: [],
      selectedTransportador: null,
      transportadorInfo: null,
      classesDisponiveis: [],
      selectedClasseParaAtribuir: null,
      showAtribuirClasseModal: false,
      errorsTransportador: {
        transportador: "",
        classe: "",
      },
    };
  },
  computed: {
    activeClasses() {
      return this.classes.filter(classe => classe.ativo);
    },
    selectedClasseDescricao() {
      const classe = this.classes.find(c => c.id === this.selectedClass);
      return classe ? classe.descricao : "N/A";
    },
    filteredClasses() {
      if (!this.searchQuery.trim()) {
        return this.classes;
      }
      return this.classes.filter(classe => 
        classe.descricao.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    totalPages() {
      return Math.ceil(this.filteredClasses.length / this.classesPerPage) || 1;
    },
    paginatedClasses() {
      const start = (this.currentPage - 1) * this.classesPerPage;
      const end = start + this.classesPerPage;
      return this.filteredClasses.slice(start, end);
    },
    showTributacaoTable() {
      return this.selectedClass && this.selectedUF && this.selectedRegime;
    },
    showAlert() {
      return (
        (this.selectedClass || this.selectedUF || this.selectedRegime) &&
        (!this.selectedClass || !this.selectedUF || !this.selectedRegime)
      );
    },
    isSimplesNacionalClass() {
      return this.selectedRegime === 1; 
    },
    // Obter a tributação selecionada
    currentSelectedTributacao() {
      return this.selectedField;
    },
    // Verifica se o campo selecionado está válido
    isAnyFieldSelected() {
      return !!this.selectedField;
    },
  },
  methods: {
    // Abrir Modal de Adicionar Classe
    openAddClassModal() {
      this.newClassDescription = "";
      this.showAddClassModal = true;
    },
    // Fechar Modal de Adicionar Classe
    closeAddClassModal() {
      this.showAddClassModal = false;
      this.errors.addClassData.descricao = "";
    },
    // Adicionar Nova Classe
    async addClass() {
      if (!this.newClassDescription.trim()) {
        this.errors.addClassData.descricao = "Descrição da classe é obrigatória.";
        return;
      }

      // Resetar erros
      this.errors.addClassData.descricao = "";

      const idUsuario = 687;

      const body = {
        idUsuario,
        descricao: this.newClassDescription.trim(),
      };

      try {
        // eslint-disable-next-line no-unused-vars
        const response = await axios.post(
          "https://api.cadastro.workinnovation.com.br/v1/tributacao/classe",
          body,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        console.log("Classe adicionada com sucesso:", response.data);
        this.showAddClassModal = false;
        this.fetchClasses();
        this.showSuccessMessage = true;
        this.successMessage = "Classe adicionada com sucesso!";
        setTimeout(() => {
          this.showSuccessMessage = false;
          this.successMessage = "";
        }, 3000);
      } catch (error) {
        console.error("Erro ao adicionar classe:", error);
        alert("Erro ao adicionar classe. Por favor, tente novamente.");
      }
    },
    // Abrir Modal de Edição de Classe
    openEditClassModal(classe) {
      this.editClassData = { 
        id: classe.id,
        descricao: classe.descricao,
        ativo: classe.ativo,
      };
      this.showEditClassModal = true;
    },
    // Fechar Modal de Edição de Classe
    closeEditClassModal() {
      this.showEditClassModal = false;
      this.editClassData = {
        id: null,
        descricao: "",
        ativo: true,
      };
      this.errors.editClassData = {};
    },
    // Atualizar Classe
    async updateClass() {
      if (!this.editClassData.descricao.trim()) {
        this.errors.editClassData.descricao = "Descrição da classe é obrigatória.";
        return;
      }

      // Resetar erros
      this.errors.editClassData.descricao = "";

      const body = {
        descricao: this.editClassData.descricao.trim(),
        ativo: this.editClassData.ativo,
      };

      try {
        // eslint-disable-next-line no-unused-vars
        const response = await axios.put(
          `https://api.cadastro.workinnovation.com.br/v1/tributacao/classe/${this.editClassData.id}`,
          body,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        console.log("Classe atualizada com sucesso:", response.data);
        this.showEditClassModal = false;
        this.fetchClasses();
        this.showSuccessMessage = true;
        this.successMessage = "Classe atualizada com sucesso!";
        setTimeout(() => {
          this.showSuccessMessage = false;
          this.successMessage = "";
        }, 3000);
      } catch (error) {
        console.error("Erro ao atualizar classe:", error);
        alert("Erro ao atualizar classe. Por favor, tente novamente.");
      }
    },
    // Deletar Classe
    async deleteClass(classe) {
      if (!confirm(`Tem certeza que deseja excluir a classe "${classe.descricao}"?`)) {
        return;
      }

      try {
        // eslint-disable-next-line no-unused-vars
        const response = await axios.delete(
          `https://api.cadastro.workinnovation.com.br/v1/tributacao/classe/${classe.id}`
        );
        console.log("Classe excluída com sucesso:", response.data);
        this.fetchClasses();
        this.showSuccessMessage = true;
        this.successMessage = "Classe excluída com sucesso!";
        setTimeout(() => {
          this.showSuccessMessage = false;
          this.successMessage = "";
        }, 3000);
      } catch (error) {
        console.error("Erro ao excluir classe:", error);
        alert("Erro ao excluir classe. Por favor, tente novamente.");
      }
    },
    // Toggle Ativo da Classe
    async toggleAtivo(classe) {
      const body = {
        descricao: classe.descricao,
        ativo: classe.ativo
      };

      try {
        // eslint-disable-next-line no-unused-vars
        const response = await axios.put(
          `https://api.cadastro.workinnovation.com.br/v1/tributacao/classe/${classe.id}`,
          body,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        console.log(`Classe "${classe.descricao}" atualizada para ativo: ${classe.ativo}`);
        this.showSuccessMessage = true;
        this.successMessage = "Status da classe atualizado com sucesso!";
        setTimeout(() => {
          this.showSuccessMessage = false;
          this.successMessage = "";
        }, 3000);
      } catch (error) {
        console.error("Erro ao atualizar status da classe:", error);
        alert("Erro ao atualizar status da classe. Por favor, tente novamente.");
        classe.ativo = !classe.ativo;
      }
    },
    // Buscar Classes
    async fetchClasses() {
      if (this.isFetchingClasses) return;
      this.isFetchingClasses = true;
      try {
        const res = await axios.get(
          "https://api.cadastro.workinnovation.com.br/v1/tributacao/classe",
          {
            params: { idUsuario: 687 },
            headers: { 'Accept': 'application/json' }
          }
        );
        this.classes = res.data;
        console.log("Classes atualizadas:", this.classes);
        this.currentPage = 1;
      } catch (error) {
        console.error("Erro ao buscar classes:", error);
        alert("Erro ao buscar classes. Por favor, tente novamente.");
      } finally {
        this.isFetchingClasses = false;
      }
    },
    // Resetar Tributacoes da Classe
    resetTributacoesClasse() {
      this.tributacoesClasse.cfop = {};
      this.tributacoesClasse.cst = {};
      this.tributacoesClasse.msgFiscal = {};
      this.tributacoesClasse.aliquota = {};
      this.tributacoesClasse.reducao = {};
      this.tributacoesClasse.indSn = {};
      this.tributacoesClasse.completeData = {};
      this.tributacoesClasseLoaded = false;
    },
    // Popular Tributacoes no Estado do Componente
    populateTributacoes(tributacoes) {
      const tributacoesTarget = this.tributacoesClasse;

      // Inicializa completeData com todas as combinações de origem e destino como vazias
      this.estados.forEach(origem => {
        if (!tributacoesTarget.completeData[origem]) {
          this.$set(tributacoesTarget.completeData, origem, {});
        }
        this.estados.forEach(destino => {
          if (!tributacoesTarget.completeData[origem][destino]) {
            this.$set(tributacoesTarget.completeData[origem], destino, {});
          }
        });
      });

      // Popula os dados recebidos da API
      tributacoes.forEach((item) => {
        const origem = item.ufRemetente;
        const destino = item.ufDestinatario;

        if (!tributacoesTarget.completeData[origem]) {
          this.$set(tributacoesTarget.completeData, origem, {});
        }

        if (!tributacoesTarget.completeData[origem][destino]) {
          this.$set(tributacoesTarget.completeData[origem], destino, {});
        }

        this.$set(tributacoesTarget.completeData[origem], destino, { ...item });

        const tributacoesProps = ['cfop', 'cst', 'aliquota', 'reducao', 'msgFiscal', 'indSn'];
        tributacoesProps.forEach(prop => {
          if (!tributacoesTarget[prop][origem]) {
            this.$set(tributacoesTarget[prop], origem, {});
          }
          if (!tributacoesTarget[prop][origem][destino]) {
            this.$set(tributacoesTarget[prop][origem], destino, 
              item[prop] !== undefined && item[prop] !== null ? item[prop] : (prop === 'aliquota' || prop === 'reducao' ? 0 : "-")
            );
          } else {
            tributacoesTarget[prop][origem][destino] = item[prop] !== undefined && item[prop] !== null ? item[prop] : (prop === 'aliquota' || prop === 'reducao' ? 0 : "-");
          }
        });
      });

      console.log("TributacoesClasse após popular:", this.tributacoesClasse);
    },
    // Formatar Valores de Moeda
    formatCurrency(value) {
      if (isNaN(value)) return "0,00";
      return parseFloat(value).toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    // Abrir Modal de Tributação para Configuração
    openTributacaoConfigModal(origem, destino) {
      // Permite abrir o modal mesmo que não exista tributação
      const tributacao = this.tributacoesClasse.completeData[origem]?.[destino] || {};

      // Preenche os dados da tributação com valores existentes ou padrões
      this.currentTributacao = {
        ufTransportador: this.selectedUF,
        ufRemetente: origem,
        ufDestinatario: destino,
        idClasse: this.selectedClass,
        regime: this.selectedRegime,
        cfop: tributacao.cfop || "",
        cst: tributacao.cst || "",
        indSn: tributacao.indSn || "",
        msgFiscal: tributacao.msgFiscal || "",
        aliquota: tributacao.aliquota || 0,
        reducao: tributacao.reducao || 0,
      };

      // Abre o modal
      this.showTributacaoConfigModal = true;
    },
    // Fechar Modal de Tributação para Edição de Classe
    closeTributacaoClassModal() {
      this.showModalTributacaoClass = false;
      this.currentTributacao = {
        ufTransportador: "",
        ufRemetente: "",
        ufDestinatario: "",
        idClasse: null,
        regime: null,
        cfop: "",
        cst: "",
        indSn: "",
        msgFiscal: "",
        aliquota: 0,
        reducao: 0,
      };
      this.errors.tributacaoClassData = {};
    },
    async saveTributacaoClassData() {
      if (!this.selectedField) {
        this.errors.selectedField = "Selecione uma tributação para exibição.";
        return;
      } else {
        this.errors.selectedField = "";
      }

      const { cfop, cst, msgFiscal, aliquota, reducao } = this.currentTributacao;
      if (!cfop || !cst || !msgFiscal) {
        alert("CFOP, CST e Mensagem Fiscal são obrigatórios.");
        return;
      }

      if (aliquota < 0 || reducao < 0) {
        alert("Alíquota e Redução não podem ser negativas.");
        return;
      }

      const body = {
        ufTransportador: this.currentTributacao.ufTransportador,
        ufRemetente: this.currentTributacao.ufRemetente,
        ufDestinatario: this.currentTributacao.ufDestinatario,
        idClasse: this.currentTributacao.idClasse,
        regime: this.currentTributacao.regime,
        cfop: this.currentTributacao.cfop,
        cst: this.currentTributacao.cst,
        indSn: this.currentTributacao.indSn,
        msgFiscal: this.currentTributacao.msgFiscal,
        aliquota: this.currentTributacao.aliquota,
        reducao: this.currentTributacao.reducao,
      };

      try {
        const response = await axios.post(
          "https://api.cadastro.workinnovation.com.br/v1/tributacao/",
          body,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        console.log("Tributação da classe salva:", response.data);

        this.showSuccessMessage = true;
        this.successMessage = "Tributação da classe salva com sucesso!";
        setTimeout(() => {
          this.showSuccessMessage = false;
          this.successMessage = "";
        }, 3000);

        this.closeTributacaoClassModal();

        this.fetchTributacoesByClass(this.selectedClass);
      } catch (error) {
        console.error("Erro ao salvar tributação da classe:", error);
        alert("Erro ao salvar tributação da classe. Por favor, tente novamente.");
      }
    },


    async buscarTransportadores() {
      if (this.isFetchingTransportadores) return;
      this.isFetchingTransportadores = true;
      try {
        const idUsuario = 687;
        const response = await axios.get(
          `https://api.cadastro.workinnovation.com.br/v1/cadastro/participante/busca-usuario`,
          {
            params: { idUsuario },
            headers: { 'Accept': 'application/json' }
          }
        );
        this.transportadores = response.data;
        console.log("Transportadores buscados:", this.transportadores);
      } catch (error) {
        console.error("Erro ao buscar transportadores:", error);
        alert("Erro ao buscar transportadores. Por favor, tente novamente.");
      } finally {
        this.isFetchingTransportadores = false;
      }
    },

    // Método para verificar se o transportador possui classe atribuída
    async buscarClasseDoTransportador() {
      if (!this.selectedTransportador) {
        this.transportadorInfo = null;
        return;
      }

      try {
        const response = await axios.get(
          `https://api.cadastro.workinnovation.com.br/v1/tributacao/transportador`,
          {
            params: { idTransportador: this.selectedTransportador },
            headers: { 'Accept': 'application/json' }
          }
        );

        this.transportadorInfo = response.data; // Supondo que a API retorne os dados diretamente

        if (this.transportadorInfo.idClasse === 0) {
          // Transportador não possui classe atribuída, buscar classes disponíveis
          this.buscarClassesDisponiveis(false);
        } else {
          // Transportador já possui uma classe atribuída
          // Buscar classesDisponiveis incluindo a classe atual se estiver inativa
          this.buscarClassesDisponiveis(true, this.transportadorInfo.idClasse);
        }
      } catch (error) {
        console.error("Erro ao buscar informações do transportador:", error);
        alert("Erro ao buscar informações do transportador. Por favor, tente novamente.");
      }
    },

    // Método para buscar classes disponíveis para atribuição ou edição
    async buscarClassesDisponiveis(isEdit = false, currentClassId = null) {
      try {
        // Fetch all classes from the API (incluindo inativas se necessário)
        const res = await axios.get(
          "https://api.cadastro.workinnovation.com.br/v1/tributacao/classe",
          {
            params: { idUsuario: 687 },
            headers: { 'Accept': 'application/json' }
          }
        );
        const allClasses = res.data;
        
        if (isEdit && currentClassId) {
          const currentClass = allClasses.find(c => c.id === currentClassId);
          if (currentClass) {
            // Inclua a classe atual mesmo que esteja inativa
            this.classesDisponiveis = this.activeClasses.concat(
              allClasses.filter(c => c.id === currentClassId && !c.ativo)
            );
          } else {
            // Se a classe atual não for encontrada, apenas liste as ativas
            this.classesDisponiveis = this.activeClasses;
          }
        } else {
          // No modo de atribuição, liste apenas as classes ativas
          this.classesDisponiveis = this.activeClasses;
        }

        console.log("Classes disponíveis para atribuição/edição:", this.classesDisponiveis);
      } catch (error) {
        console.error("Erro ao buscar classes disponíveis:", error);
        alert("Erro ao buscar classes disponíveis. Por favor, tente novamente.");
      }
    },

    // Método para abrir o modal de atribuição de classe (atribuição ou edição)
    abrirAtribuirClasseModal(isEdit = false) {
      this.showAtribuirClasseModal = true;
      this.errorsTransportador.classe = "";
      this.isEditingTransportadorClass = isEdit;
      if (isEdit && this.transportadorInfo) {
        // Preselecionar a classe atual no modo de edição
        this.selectedClasseParaAtribuir = this.transportadorInfo.idClasse;
        // Buscar classes disponíveis incluindo a atual
        this.buscarClassesDisponiveis(true, this.transportadorInfo.idClasse);
      } else {
        this.selectedClasseParaAtribuir = null;
        // Buscar classes disponíveis apenas ativas
        this.buscarClassesDisponiveis();
      }
    },

    // Método para fechar o modal de atribuição de classe
    fecharAtribuirClasseModal() {
      this.showAtribuirClasseModal = false;
      this.selectedClasseParaAtribuir = null;
      this.errorsTransportador.classe = "";
      this.isEditingTransportadorClass = false;
    },

    // Método para atribuir ou editar uma classe ao transportador
    async atribuirClasseAoTransportador() {
      if (!this.selectedClasseParaAtribuir) {
        this.errorsTransportador.classe = "Selecione uma classe para atribuir.";
        return;
      }

      try {
        // Montar o endpoint com os parâmetros na URL
        const endpoint = `https://api.cadastro.workinnovation.com.br/v1/tributacao/transportador/${this.selectedTransportador}?idClasse=${this.selectedClasseParaAtribuir}`;

        // Enviar requisição com método PUT
        const response = await axios.put(endpoint, null, {
          headers: { 'Content-Type': 'application/json' },
        });

        console.log(`${this.isEditingTransportadorClass ? 'Classe editada' : 'Classe atribuída'} com sucesso:`, response.data);
        alert(`${this.isEditingTransportadorClass ? 'Classe editada' : 'Classe atribuída'} com sucesso!`);

        // Atualizar as informações do transportador
        this.buscarClasseDoTransportador();
        this.fecharAtribuirClasseModal();
      } catch (error) {
        console.error(`${this.isEditingTransportadorClass ? 'Erro ao editar' : 'Erro ao atribuir'} classe ao transportador:`, error);
        alert(`${this.isEditingTransportadorClass ? 'Erro ao editar' : 'Erro ao atribuir'} classe ao transportador. Por favor, tente novamente.`);
      }
    },

    // Método para remover a classe atribuída ao transportador
    async removerClasseDoTransportador() {
      if (!confirm("Tem certeza que deseja remover a classe atribuída a este transportador?")) {
        return;
      }

      try {
        const body = {
          idTransportador: this.selectedTransportador,
          idClasse: 0,
          idUsuario: 687, 
        };

        const response = await axios.post(
          `https://api.cadastro.workinnovation.com.br/v1/tributacao/transportador/remover-classe`,
          body,
          {
            headers: { 'Content-Type': 'application/json' }
          }
        );

        console.log("Classe removida com sucesso:", response.data);
        alert("Classe removida com sucesso!");

        // Atualizar as informações do transportador
        this.buscarClasseDoTransportador();
      } catch (error) {
        console.error("Erro ao remover classe do transportador:", error);
        alert("Erro ao remover classe do transportador. Por favor, tente novamente.");
      }
    },

    // Navegação de Páginas
    changePage(newPage) {
      if (newPage >= 1 && newPage <= this.totalPages) {
        this.currentPage = newPage;
      }
    },

    // Fechar Modal de Configuração de Tributação
    closeTributacaoConfigModal() {
      this.showTributacaoConfigModal = false;
      this.currentTributacao = {
        ufTransportador: "",
        ufRemetente: "",
        ufDestinatario: "",
        idClasse: null,
        regime: null,
        cfop: "",
        cst: "",
        indSn: "",
        msgFiscal: "",
        aliquota: 0,
        reducao: 0,
      };
      this.errors.currentTributacao = {};
    },
    // Salvar Configuração de Tributação via Modal
    async saveTributacaoConfigData() {
      // Validação do campo selecionado para exibição
      if (!this.selectedField) {
        this.errors.selectedField = "Selecione uma tributação para exibição.";
        return;
      } else {
        this.errors.selectedField = "";
      }

      // Validações Básicas
      const { cfop, cst, msgFiscal, aliquota, reducao } = this.currentTributacao;
      if (!cfop || !cst || !msgFiscal) {
        alert("CFOP, CST e Mensagem Fiscal são obrigatórios.");
        return;
      }

      if (aliquota < 0 || reducao < 0) {
        alert("Alíquota e Redução não podem ser negativas.");
        return;
      }

      const body = {
        ufTransportador: this.currentTributacao.ufTransportador,
        ufRemetente: this.currentTributacao.ufRemetente,
        ufDestinatario: this.currentTributacao.ufDestinatario,
        idClasse: this.currentTributacao.idClasse,
        regime: this.currentTributacao.regime,
        cfop: this.currentTributacao.cfop,
        cst: this.currentTributacao.cst,
        indSn: this.currentTributacao.indSn,
        msgFiscal: this.currentTributacao.msgFiscal,
        aliquota: this.currentTributacao.aliquota,
        reducao: this.currentTributacao.reducao,
      };

      try {
        const response = await axios.post(
          "https://api.cadastro.workinnovation.com.br/v1/tributacao/",
          body,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        console.log("Tributação da classe salva:", response.data);

        // Atualize o estado conforme necessário
        this.showSuccessMessage = true;
        this.successMessage = "Tributação da classe salva com sucesso!";
        setTimeout(() => {
          this.showSuccessMessage = false;
          this.successMessage = "";
        }, 3000);

        // Feche o modal após salvar
        this.closeTributacaoConfigModal();

        // Atualize as tributações da classe para refletir as alterações
        this.fetchTributacoesByClass(this.selectedClass);
      } catch (error) {
        console.error("Erro ao salvar tributação da classe:", error);
        alert("Erro ao salvar tributação da classe. Por favor, tente novamente.");
      }
    },

    // Buscar Tributacoes por Classe (para Configuração de Classes)
    async fetchTributacoesByClass(idClasse) {
      if (!idClasse) {
        this.resetTributacoesClasse();
        this.tributacoesClasseLoaded = false;
        return;
      }

      this.loadingTributacoesClasse = true;
      this.tributacoesClasseLoaded = false;

      try {
        const res = await axios.get(
          "https://api.cadastro.workinnovation.com.br/v1/tributacao/",
          {
            params: { idClasse, ufTransportador: this.selectedUF, regime: this.selectedRegime },
            headers: { Accept: "application/json" },
          }
        );

        const data = res.data?.tributacoes || []; 
        if (data.length === 0) {
          console.warn(`Nenhuma tributação encontrada para a classe ID ${idClasse}.`);
        }

        console.log(`Dados retornados da API para classe (tributacoes):`, data);

        this.populateTributacoes(data);
        this.tributacoesClasseLoaded = true;
      } catch (error) {
        console.error(`Erro ao buscar tributações para a classe ID ${idClasse}:`, error);
        alert(`Erro ao buscar tributações para a classe. Por favor, tente novamente.`);
      } finally {
        this.loadingTributacoesClasse = false;
      }
    },

    setTributacaoClassHoverIndices(rowIndex, colIndex) {
      this.tributacaoClassHoverRowIndex = rowIndex;
      this.tributacaoClassHoverColIndex = colIndex;
    },
    resetTributacaoClassHoverIndices() {
      this.tributacaoClassHoverRowIndex = null;
      this.tributacaoClassHoverColIndex = null;
    },


    getTransportadorNome(id) {
      const transportador = this.transportadores.find(t => t.id === id);
      if (transportador) {
        return `${transportador.nomeFantasia} (${transportador.razaoSocial})`;
      }
      return "N/A";
    },

    getClasseDescricao(idClasse) {
      const classe = this.classes.find(c => c.id === idClasse);
      return classe ? classe.descricao : "N/A";
    },
  },
  mounted() {
    this.fetchClasses();
  },
  watch: {
    activeTab(newTab) {
      if (newTab === "gerenciamento") {
        this.fetchClasses();
      }
      if (newTab === "classes-transportador" && this.transportadores.length === 0) {
        this.buscarTransportadores();
      }
    },
    searchQuery() {
      this.currentPage = 1;
    },
    selectedClass(newVal) {
      if (this.showTributacaoTable) {
        this.fetchTributacoesByClass(newVal);
      } else {
        this.resetTributacoesClasse();
      }
    },
    selectedUF() {
      if (this.showTributacaoTable) {
        this.fetchTributacoesByClass(this.selectedClass);
      } else {
        this.resetTributacoesClasse();
      }
    },
    selectedRegime() {
      if (this.showTributacaoTable) {
        this.fetchTributacoesByClass(this.selectedClass);
      } else {
        this.resetTributacoesClasse();
      }
    },
    selectedField(newVal) {
      if (!newVal) {
        this.errors.selectedField = "Selecione uma tributação para exibição.";
      } else {
        this.errors.selectedField = "";
      }
    },
  },
};
</script>
<style scoped>
.tributacao-app-container {
  background-color: #ffffff;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.tributacao-success-notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #28a745;
  color: #fff;
  padding: 15px 20px;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1100;
  animation: tributacao-slideIn 0.5s ease-out, tributacao-fadeOut 0.5s ease-in 2.5s;
}

@keyframes tributacao-slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes tributacao-fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.tributacao-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}

.tributacao-modal-class {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1200;
}

.tributacao-modal-content-class,
.tributacao-modal-content {
  background: #fff;
  padding: 30px 40px;
  border-radius: 10px;
  width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  animation: tributacao-fadeIn 0.3s ease-in-out;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 20px;
}

.tributacao-modal-content > * {
  flex: 1; 
  max-width: 100%; 
  display: flex; 
  justify-content: center;
  align-items: center;
}

@keyframes tributacao-fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.tributacao-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
  transition: color 0.2s;
}

.tributacao-close-button:hover {
  color: #ff0000;
}

.tributacao-modal-title {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
  text-align: center;
}

.tributacao-modal-form {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tributacao-form-group {
  display: flex;
  flex-direction: column;
}

.tributacao-selection-section {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.tributacao-info-filter {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}



.tributacao-custom-select[multiple],
.tributacao-custom-select {
  width: 100%;
  padding: 10px 40px 10px 12px; 
  border-radius: 4px;
  border: 1px solid #007bff;
  background-color: #ffffff;
  font-size: 1rem;
  cursor: pointer;
  transition: border-color 0.2s, box-shadow 0.2s;
  color: black !important; 
}

.tributacao-custom-select option {
  color: black !important;
  background-color: #ffffff !important;
}



.tributacao-custom-select-container {
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: white;
}

.tributacao-select-arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  pointer-events: none;
  transform: translateY(-50%);
  color: #007bff;
  font-size: 1rem;
}

.tributacao-error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 5px;
}

.tributacao-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.tributacao-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.tributacao-btn-primary {
  background-color: #4a90e2;
  color: #fff;
}

.tributacao-btn-primary:hover {
  background-color: #357abd;
}

.tributacao-btn-secondary {
  background-color: #ccc;
  color: #333;
}

.tributacao-btn-secondary:hover {
  background-color: #b3b3b3;
}

.tributacao-tabs {
  display: flex;
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
}

.tributacao-tab {
  flex: 1;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f8f9fa;
  border: 1px solid transparent;
  outline: none;
  transition: background-color 0.3s;
  font-size: 1rem;
  color: black;
}

.tributacao-tab:hover {
  background-color: #e2e6ea;
  color: black;
}

.tributacao-tab.active {
  background-color: #ffffff;
  border-bottom: 2px solid #4a90e2;
  font-weight: bold;
  color: #4a90e2;
}

.tributacao-header-gerenciamento {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.tributacao-add-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.tributacao-add-button:hover {
  background-color: #218838;
}

.tributacao-search-bar {
  position: relative;
  width: 250px;
}

.tributacao-search-input {
  width: 100%;
  padding: 8px 35px 8px 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.tributacao-search-input:focus {
  border-color: #4a90e2;
  outline: none;
}

.tributacao-search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #4a90e2;
  pointer-events: none;
  width: 25px;
  height: 25px;
}

.tributacao-classes-list {
  margin-top: 20px;
}

.tributacao-classes-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.tributacao-classes-table th,
.tributacao-classes-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

.tributacao-classes-table th {
  background-color: #4a90e2;
  color: white;
}

.tributacao-action-button {
  padding: 8px 12px;
  margin: 0 4px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  color: white;
  transition: background-color 0.2s;
}

.tributacao-edit-button {
  background-color: #4a90e2;
}

.tributacao-edit-button:hover {
  background-color: #357abd;
}

.tributacao-delete-button {
  background-color: #dc3545;
}

.tributacao-delete-button:hover {
  background-color: #c82333;
}

.tributacao-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 15px;
}

.tributacao-pagination-button {
  padding: 8px 16px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.tributacao-pagination-button:disabled {
  background-color: #a0c4e8;
  cursor: not-allowed;
}

.tributacao-pagination-button:hover:not(:disabled) {
  background-color: #357abd;
}

.tributacao-pagination-info {
  font-size: 1rem;
  color: #333;
}

.tributacao-inactive-class {
  background-color: #f2f2f2;
  color: #888;
}

.tributacao-alert-warning {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  padding: 15px;
  border-radius: 6px;
  margin-top: 20px;
}

.tributacao-table-container {
  margin-top: 20px;
  overflow-x: auto;
  display: flex;
  justify-content: center;
}

.tributacao-table {
  width: auto;
  table-layout: fixed;
  border-collapse: collapse;
  font-size: 1rem;
}

.tributacao-table th,
.tributacao-table td {
  width: 35px; 
  max-width: 35px;
  min-width: 35px;
  height: 25px;
  border: 1px solid #4a90e2;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tributacao-table th {
  background-color: #4a90e2;
  color: #fff;
  font-weight: bold;
}

.tributacao-state-column {
  background-color: #4a90e2;
  color: #fff;
  font-weight: bold;
  width: 200px;
}

.tributacao-clickable-cell {
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: white;
}

.tributacao-clickable-cell:hover {
  background-color: #fbff00;
}

.tributacao-highlight-border {
  border: 2px solid red !important;
}

.tributacao-loader {
  text-align: center;
  font-size: 1.2rem;
  color: #4a90e2;
  margin-top: 20px;
}

.tributacao-no-data {
  text-align: center;
  font-size: 1rem;
  color: #888;
}

.tributacao-under-construction {
  text-align: center;
  padding: 50px;
  color: #888;
}

.tributacao-input-error {
  border-color: #dc3545;
}

.tributacao-editable-cell {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.tributacao-table-input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
}

.tributacao-cell-display span {
  display: block;
}

.tributacao-flag-image{
  width: 35px;
  height: 30px;
}

.tributacao-same-origin-dest {
  background-color: #4a90e2; 
  color: #ffffff; 
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tributacao-same-origin-dest:hover {
  background-color: #daa71d; 
}

.tributacao-classes-transportador-container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.tributacao-transportador-info {
  margin-top: 20px;
  font-size: 1rem;
  color: #333;
}

.tributacao-transportador-info p {
  margin-bottom: 15px;
}

.tributacao-btn-primary {
  background-color: #28a745;
}

.tributacao-btn-primary:hover {
  background-color: #218838;
}

.tributacao-btn-secondary {
  background-color: #dc3545;
  color: white;
}

.tributacao-btn-secondary:hover {
  background-color: #c82333;
  color:white;
}

.tributacao-loading-text {
  margin-left: 10px;
  font-size: 0.9rem;
  color: #4a90e2;
}
.tributacao-form-input,
.tributacao-search-input,
.tributacao-modal-form input[type="text"],
.tributacao-modal-form input[type="number"],
.tributacao-modal-form textarea {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 1rem;
  color: #333;
  transition: border-color 0.2s, box-shadow 0.2s;
  box-sizing: border-box;
}

.tributacao-form-input:focus,
.tributacao-search-input:focus,
.tributacao-modal-form input[type="text"]:focus,
.tributacao-modal-form input[type="number"]:focus,
.tributacao-modal-form textarea:focus {
  border-color: #4a90e2;
  outline: none;
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
}

.tributacao-form-input:disabled,
.tributacao-search-input:disabled,
.tributacao-modal-form input[type="text"]:disabled,
.tributacao-modal-form input[type="number"]:disabled,
.tributacao-modal-form textarea:disabled {
  background-color: #f2f2f2;
  color: #aaa;
  cursor: not-allowed;
}

.tributacao-form-input::placeholder,
.tributacao-search-input::placeholder,
.tributacao-modal-form input[type="text"]::placeholder,
.tributacao-modal-form input[type="number"]::placeholder,
.tributacao-modal-form textarea::placeholder {
  color: #aaa;
}

.tributacao-input-error {
  border-color: #dc3545;
}

.tributacao-form-input-checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
  accent-color: #4a90e2;
}

.tributacao-form-label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.tributacao-form-group {
  margin-bottom: 15px;
}

.tributacao-table-input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
  box-sizing: border-box;
}

.tributacao-clickable-cell input {
  width: 100%;
  border: none;
  background: transparent;
  text-align: center;
}

.tributacao-modal-form input[type="text"],
.tributacao-modal-form input[type="number"],
.tributacao-modal-form textarea {
  margin-top: 5px;
}
</style>
